import {Model} from "./Model";
import {FieldClass, FieldSourceType, FieldType, IModelField, ModelSchema} from "../validation/Validation";
import {Required} from "../validation/Validators";

export interface User extends Model {
    readonly user_id: string
    readonly first_name: string
    readonly last_name: string
    readonly email: string
}

export class UserSchemaDef extends ModelSchema<User> {
    public getFriendlyName(value: User): string {
        return value.email
    }

    get SchemaName(): string {
        return "User"
    }

    get apiResourceName(): string {
        return "users";
    }

    get KeyColumns(): IModelField<any, User>[] {
        return [this.UserId];
    }

    public readonly UserId = FieldClass.createIdField<User>("user_id")

    public readonly FirstName = new FieldClass<string, User>({
        field_type: FieldType.TEXT,
        display_name: "First Name", name: "first_name", field_source: FieldSourceType.USER_DEFINED, rules: [
            new Required<User>(),
        ]
    })
    public readonly LastName = new FieldClass<string, User>({
        field_type: FieldType.TEXT,
        display_name: "Last Name", name: "last_name", field_source: FieldSourceType.USER_DEFINED, rules: [
            new Required<User>(),
        ]
    })
    public readonly Email = new FieldClass<string, User>({
        field_type: FieldType.TEXT,
        display_name: "Email", name: "email", field_source: FieldSourceType.SYSTEM_DEFINED, rules: []
    })

}

export const UserSchema: UserSchemaDef = new UserSchemaDef()
