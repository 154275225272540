import { ReactNode } from "react"
import {Link} from "react-router-dom"

function ZLink(props: Props) {

    return (
        <Link to={props.href}>{props.children}</Link>
    )
}

interface Props {
    readonly href: string
    readonly children: ReactNode
}

export default ZLink