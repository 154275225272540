import {ReactNode} from "react";
import PowerSideBar from "./PowerNav";
import PowerTopBar from "./PowerTopBar";
import IsUserRegisteredGuard from "../routing/IsUserRegisteredGuard";
import IsOrgSelectedGuard from "../routing/IsOrgSelectedGuard";
import IsUserSetUpGuard from "../routing/IsUserSetUpGuard";

function PowerPage(props: Props) {

    return (
        <IsUserRegisteredGuard>
            <IsUserSetUpGuard>
                <div className="bg-body-tertiary">
                    <div className="d-flex flex-column flex-lg-row h-lg-100 gap-1">
                        <PowerSideBar/>
                        <div className="flex-lg-fill ps-lg-1 vstack vh-lg-100 position-relative">
                            <PowerTopBar/>
                            <div
                                className="flex-fill overflow-y-lg-auto scrollbar bg-body rounded-top-4 rounded-top-start-lg-4 rounded-top-end-lg-0 border-top border-lg shadow-2">
                                <main className="container-fluid px-3 py-5 p-lg-6 p-xxl-8">
                                    <IsOrgSelectedGuard>
                                        {props.children}
                                    </IsOrgSelectedGuard>
                                </main>
                            </div>
                        </div>
                    </div>
                </div>
            </IsUserSetUpGuard>
        </IsUserRegisteredGuard>
    )
}

interface Props {
    children: ReactNode
}

export default PowerPage