import * as React from "react";
import {ReactNode, useContext, useEffect, useState} from "react";
import {UserContext} from "../../context/UserContext";
import {useParams} from "react-router-dom";
import {
    Campaign, CampaignSchema,
    Draft,
    DraftSchema,
    DraftStates,
    EmailSection,
    getDraftSectionOrDefault,
    getGenerationFilters,
    getSimpleGenCategory, isSuccess,
    LeadSchema,
    NonPersonalizedGenerationCategories,
    PersonalizedGenerationCategories
} from "lib-shared";
import IconButton from "../../components/common/IconButton";
import EmailExporter from "../../components/email/exporter/EmailExporter";
import {useAppNav} from "../../hooks/useAppNav";
import PowerPageContent from "../../components/powerPage/PowerPageContent";
import {ColumnSpec, FilterOption} from "../../components/tables/BaseTable";
import MaterialIcon from "../../components/common/MaterialIcon";
import {useApiGet} from "../../hooks/useApiGet";
import ListPickerModal from "../../components/listPicker/ListPickerModal";
import Button from "../../components/common/Button";
import {CampaignsClient, DraftsClient} from "lib-client";
import SlimMetric from "../../components/metrics/SlimMetric";
import {ellipsis} from "../../utils/Utils";
import CampaignDefaultModal from "../campaigns/CampaignDefaultModal";
import ApiTable, {ApiFilter, ApiOrderBy, createColumn, createColumnFromNested} from "../../components/tables/ApiTable";
import {toastIfError} from "../../context/toasts/ToastManager";
import {linkDraftEditor} from "./DraftEditorPage";

function Outbox() {
    const nav = useAppNav()
    const { clientContext} = useContext(UserContext);

    const orderByOptions = [{label: "Name", value: "name"}]
    const orderBy = orderByOptions[0].value


    const params = useParams();

    // might not be defined
    const campaignId = params["campaignId"] ?? null

    const [showDefaultMessage, setShowDefaultMessage] = useState(false)
    const [showExporter, setShowExporter] = useState(false)
    const [showCampaignPicker, setShowCampaignPicker] = useState(false)

    const campaignResponse = useApiGet<Campaign>(clientContext, CampaignsClient.defaultClient, campaignId, true)
    const campaign: Campaign | null = campaignResponse.value

    useEffect(() => {
        if (!campaignId) {
            setShowCampaignPicker(true)
        } else if (!campaignResponse.isLoading && !campaignResponse.value) {
            setShowCampaignPicker(true)
        } else {
            setShowCampaignPicker(false)
        }
    }, [campaignResponse.isLoading, campaignId, campaignResponse.value]);

    const filters: FilterOption<ApiFilter>[] = [
        {
            name: "State",
            filters: DraftStates.map<ApiFilter>(state => {
                return {
                    displayName: "State",
                    displayValue: state,
                    field: DraftSchema.State.name,
                    isDefault: state == "generating" || state == "ready",
                    value: [state]
                }
            })
        },
        {
            name: "Personalized",
            filters: [
                {
                    displayName: "Personalized",
                    displayValue: "true",
                    field: DraftSchema.GenerationCategory.name,
                    isDefault: false,
                    value: PersonalizedGenerationCategories
                },
                {
                    displayName: "Personalized",
                    displayValue: "false",
                    field: DraftSchema.GenerationCategory.name,
                    isDefault: false,
                    value: NonPersonalizedGenerationCategories
                },
            ]
        }
    ]

    const columns: ColumnSpec<Draft, ApiOrderBy>[] = [
        createColumnFromNested(LeadSchema.LastName, true, draft => draft.lead, {size: "sm"}),
        createColumnFromNested(LeadSchema.FirstName, true, draft => draft.lead, {size: "sm"}),
        createColumn(DraftSchema.Subject, true, {size: "sm"}),
        createColumn(DraftSchema.Body, true, {
            orderByEnabled: false,
            size: "lg",
            valueFormatter: (value, model) => ellipsis(getDraftSectionOrDefault(model, campaign!, EmailSection.Body), 50)
        }),
        createColumn(DraftSchema.State, true, {size: "sm"}),
        {
            name: "Personalized",
            defaultColumn: true,
            size: "sm",
            cellExtractor: draft => {
                let genCategory: ReactNode = null
                switch (getSimpleGenCategory(draft.generation_category)) {
                    case "pending":
                        genCategory = ""
                        break;
                    case "generated":
                        genCategory =
                            <span className={"text-success"}><MaterialIcon icon={"check-circle-fill"} margin={2}/>Personalized</span>
                        break;
                    case "default":
                        genCategory =
                            <span className={"text-muted"}><MaterialIcon icon={"circle"} margin={2}/>Default</span>
                }
                return {
                    value: genCategory
                }
            },
        },
        {
            name: "State",
            defaultColumn: true,
            size: "sm",
            orderBy: {
                value: DraftSchema.State,
            },
            cellExtractor: draft => {
                let state: ReactNode = null
                switch (draft.state) {
                    case "generating":
                        state = <span className="spinner-border spinner-border-sm" role="status"><span
                            className="visually-hidden">Loading...</span></span>
                        break;
                    case "ready":
                        state = <>Ready</>
                        break;
                    case "sent":
                        state = <><MaterialIcon icon={"check-circle-fill"} color={"success"} size={18}
                                                margin={2}/>Sent</>
                        break;
                    case "sending":
                        state = <><MaterialIcon icon={"send-fill"} color={"success"} size={18} margin={2}/>Sending</>
                        break;
                    case "skipped":
                        state = <><MaterialIcon icon={"clock"} color={"warning"} size={18} margin={2}/>Skipped</>
                        break;
                }
                return {
                    value: state
                }
            },
        },
        createColumn(DraftSchema.UpdatedAt, true, {
            size: "sm",
        }),
        createColumn(DraftSchema.CreatedAt, false, {
            size: "sm",
        }),
    ]

    function setSelectedDraftHandler(draft: Draft | null) {
        if (draft != null)
            nav(linkDraftEditor(draft))
        // setSelectedDraftId(draft?.draft_id ?? null)
    }

    async function launchCampaign() {
        const updated: Campaign = {
            ...campaign!,
            state: "launched"
        }
        const response = await new CampaignsClient().update({value: updated}, clientContext!)
        toastIfError(clientContext, response)
        if (isSuccess(response)) {
            campaignResponse.rerun()
        }
    }

    return (
        <PowerPageContent title={`${campaign?.name ?? ''} - Outbox`} buttons={[
            <IconButton key={"default-msg-view"} variant={"outline-primary"} action={() => setShowDefaultMessage(true)}
                        icon={"file-earmark-text"}
                        label={"View Default Message"}/>,
            <IconButton key={"export-csv"} variant={"outline-primary"} action={() => setShowExporter(true)}
                        icon={"download"}
                        label={"Export CSV"}/>,
        ]}>
            {campaign?.state == "draft" &&
                <div className="alert alert-warning mb-3" role="alert">
                    <p>
                        This campaign needs to be launched before it can be used.
                    </p>
                    <div className={"mt-3"}>
                        <Button variant={"outline-primary"} label={"Launch"} onClick={launchCampaign}/>
                    </div>
                </div>
            }
            <div className={"row"}>
                <div className={"d-flex justify-content-start"}>
                    {campaignId &&
                        <>
                            <SlimMetric client={DraftsClient.defaultClient} filters={[{
                                field: CampaignSchema.CampaignId.name,
                                value: [campaignId]
                            }, ...getGenerationFilters("generated")]}
                                        minValue={1}
                                        label={"emails personalized by your AI"} color={"success"} icon={"cpu"}/>
                            <SlimMetric client={DraftsClient.defaultClient} filters={[{field: CampaignSchema.CampaignId.name, value: [campaignId]}, {
                                field: DraftSchema.State.name,
                                value: ["generating"]
                            }]}
                                        minValue={1}
                                        spinner={true}
                                        label={"emails being generated"} color={"primary"} icon={""}/>
                        </>
                    }
                </div>
            </div>
            {campaignId &&
                <ApiTable<Draft> client={DraftsClient.defaultClient}
                                 noDataMessage={"No Drafts."}
                                 listOptions={{
                                     fullyHydrated: true,
                                     filterFields: [
                                         {
                                             field: CampaignSchema.CampaignId.name,
                                             value: [campaignId]
                                         }
                                     ]
                                 }}
                                 tableSettings={{
                                     columns,
                                     idExtractor: draft => draft.draft_id,
                                     filterOptions: filters,
                                     rowClickCallback: setSelectedDraftHandler,
                                 }}
                />}
            {campaign && <EmailExporter campaign={campaign} setShow={setShowExporter} show={showExporter}/>}
            {campaignId && <CampaignDefaultModal show={showDefaultMessage} close={() => setShowDefaultMessage(false)}
                                                 campaign_id={campaignId}/>}
            <ListPickerModal<Campaign> show={showCampaignPicker}
                                       title={"Pick a Campaign"}
                                       description={"Pick a campaign to view its outbox."}
                                       client={CampaignsClient.defaultClient}
                                       filters={[{
                                           field: CampaignSchema.State.name,
                                           value: ["launched"]
                                       }]}
                                       extraButtons={
                                           <Button variant={"outline-primary"} label={"Cancel"}
                                                   onClick={() => nav("/")}/>
                                       }
                                       isRequired={true}
                                       getId={c => c.campaign_id}
                                       mapper={c => c.name}
                                       callback={(campaign: Campaign | null) => {
                                           if (campaign) {
                                               nav(getCampaignOutboxLink(campaign))
                                           }
                                       }}/>
        </PowerPageContent>
    )
}

export function getCampaignOutboxLink(campaign: Campaign | null) {
    return getCampaignIdOutboxLink(campaign?.campaign_id ?? null)
}

export function getCampaignIdOutboxLink(campaign: string | null) {
    if (!campaign) {
        return "/outbox"
    }
    return `/outbox/${campaign}`
}

export default Outbox;