import {useState} from 'react'
import {Link} from 'react-router-dom'
import {signInWithEmailAndPassword} from "firebase/auth";
import {auth} from "../../utils/Firebase";
import {DefaultSigninRedirect} from "../Routes";
import {useAppNav} from "../../hooks/useAppNav";
import AppForm, {FormSubmitPayload} from "../../components/forms/AppForm";
import {Credentials, credentialsFields, credentialsSchema} from "../register/Register";


function UsernamePasswordSignin() {
    const email = useState("")
    const password = useState("")
    const navigate = useAppNav()

    async function handleSubmit(req: FormSubmitPayload<Credentials>): Promise<boolean> {
        const userCredential = await signInWithEmailAndPassword(auth, email[0], password[0])

        if (userCredential) {
            navigate(DefaultSigninRedirect)
            return true
        }
        return false
    }

    return (
        <div className="">
            <AppForm submitLabel={"Register"}
                     onSubmit={handleSubmit}
                     schema={credentialsSchema}
                     fields={credentialsFields}
            />
            <div className="m-2">
                <Link to={"/forgotpassword"}>Forgot password?</Link>
            </div>
            <div className="m-3 border-bottom"/>
            <div className="m-2">
                Don't have an account? <Link to={"/register"}>Register</Link>
            </div>
        </div>
    )
}

export default UsernamePasswordSignin
