import {useContext} from "react";
import {UserContext} from "../../context/UserContext";
import Button from "react-bootstrap/Button";
import {ToastContext} from "../../context/toasts/ToastManager";

function TestingPage(props: Props) {
    const {fbUser, clientContext} = useContext(UserContext);
    const toastProps = useContext(ToastContext)

    return (
        <div>
            <Button onClick={() => {
                clientContext.toastProps.toast({
                    title: "Toast Test",
                    body: "This is a test",
                })
            }}>Toast ClientContext</Button>
            <Button onClick={() => {
                toastProps.toast({
                    title: "Toast Test",
                    body: "This is a test",
                })
            }}>Toast Raw</Button>
        </div>
    )
}

interface Props {

}

export default TestingPage