import * as React from "react";
import {useContext, useState} from "react";
import {UserContext} from "../../../context/UserContext";
import {Campaign, CampaignSchema, Draft, isSuccess, Lead, LeadGroup,} from "lib-shared";
import CampaignEditor from "../CampaignEditor";
import IconButton from "../../../components/common/IconButton";
import {CampaignsClient, DraftsClient, LeadGroupsClient, LeadsClient} from "lib-client";
import PowerPageContent from "../../../components/powerPage/PowerPageContent";
import {ColumnSpec} from "../../../components/tables/BaseTable";
import {useQueryState} from "../../../hooks/useQueryParam";
import {getCampaignOutboxLink} from "../../outbox/Outbox";
import {useAppNav} from "../../../hooks/useAppNav";
import ListPickerModal from "../../../components/listPicker/ListPickerModal";
import {v4 as uuidv4} from "uuid";
import CampaignDefaultModal from "../CampaignDefaultModal";
import {ConfirmModalContext} from "../../../context/confirmModal/ConfirmModalContext";
import ApiTable, {ApiOrderBy, createColumn,} from "../../../components/tables/ApiTable";
import {TableAction} from "../../../components/tables/TableActions";
import {toastIfError} from "../../../context/toasts/ToastManager";
import {makeArchiveActions} from "../../../components/tables/ApiTableUtils";

export const CampaignEditorKey = "campaign_id";

function CampaignsPage() {
    const navigate = useAppNav();

    const {clientContext} = useContext(UserContext);
    const confirmProps = useContext(ConfirmModalContext);


    const [editId, setEditId] = useQueryState(CampaignEditorKey);
    const [sendLeadCampaignId, setSendLeadCampaignId] = useState<string | null>(
        null,
    );
    const [sendGroupCampaignId, setSendGroupCampaignId] = useState<string | null>(
        null,
    );
    const [showCampaignDefaultId, setShowCampaignDefaultId] = useState<
        string | null
    >(null);
    const [duplicateCampaign, setDuplicateCampaign] = useState<Campaign | null>(
        null,
    );

    function createCampaign() {
        setEditId("");
    }

    const columns: ColumnSpec<Campaign, ApiOrderBy>[] = [
        createColumn(CampaignSchema.Name, true, {
            cellConfig: {
                weight: "fw-semibold",
            },
        }),
        createColumn(CampaignSchema.State, true),
        createColumn(CampaignSchema.CoreMessage, false),
        createColumn(CampaignSchema.CreatedAt, false),
        createColumn(CampaignSchema.UpdatedAt, true),
    ];

    function actionCreator(campaigns: Campaign[]): TableAction[] {
        return [
            {
                icon: "pencil",
                enabled: campaigns[0].state == "draft",
                label: "Edit",
                action: () => setEditId(campaigns[0].campaign_id),
            },
            {
                icon: "plus",
                enabled: campaigns[0].state == "launched",
                label: "Add Lead",
                action: () => setSendLeadCampaignId(campaigns[0].campaign_id),
            },
            {
                icon: "plus",
                enabled: campaigns[0].state == "launched",
                label: "Add Group",
                action: () => setSendGroupCampaignId(campaigns[0].campaign_id),
            },
            {
                icon: "plus",
                enabled: campaigns[0].state == "launched",
                label: "Add All Leads",
                action: async () => {
                    await new CampaignsClient().addLeads(
                        {
                            campaign_id: campaigns[0].campaign_id,
                            type: "all",
                            values: [],
                        },
                        clientContext,
                    );
                },
            },
            {
                icon: "file-earmark-text",
                enabled: campaigns[0].state == "launched",
                label: "View Default Message",
                action: () => setShowCampaignDefaultId(campaigns[0].campaign_id),
            },
            {
                icon: "copy",
                label: "Duplicate",
                action: () => {
                    setEditId("");
                    setDuplicateCampaign(campaigns[0]);
                },
            },
            {
                icon: "trash",
                label: "Delete",
                color: "danger",
                enabled: campaigns.every(c => c.state == "draft"),
                supportsMultiRows: true,
                action: () => {
                    confirmProps.confirm({
                        action_type: "delete",
                        resources: campaigns.map((c) => {
                            return {
                                id: c.campaign_id,
                                name: c.name,
                            };
                        }),
                        client: CampaignsClient.defaultClient,
                    });
                },
            },
            ...makeArchiveActions(campaigns, confirmProps, CampaignsClient.defaultClient, clientContext)
        ];
    }

    function campaignClick(campaign: Campaign): void {
        // draft campaigns don't have a outbox yet
        if (campaign.state == "draft") {
            setEditId(campaign.campaign_id);
        } else {
            navigate(getCampaignOutboxLink(campaign));
        }
    }

    async function addLeadCallback(lead: Lead | null) {
        if (lead == null) {
            setSendLeadCampaignId(null);
            return;
        }
        const draft: Draft = {
            org_id: "",
            campaign_id: sendLeadCampaignId!,
            draft_id: uuidv4(),
            lead_id: lead.lead_id,
            state: "generating",
            generation_category: "pending",
        };
        const result = await new DraftsClient().create(draft, clientContext)
        if (!isSuccess(result)) {
            toastIfError(clientContext, result);
            return;
        }
        setSendLeadCampaignId(null);
    }

    return (
        <PowerPageContent
            title={"Campaigns"}
            buttons={[
                <IconButton
                    key={"campaign-create"}
                    action={createCampaign}
                    icon={"plus-circle"}
                    label={"New Campaign"}
                />,
            ]}
        >
            <ApiTable<Campaign>
                client={CampaignsClient.defaultClient}
                noDataMessage={"No Campaigns yet."}
                enableArchive={true}
                tableSettings={{
                    columns,
                    idExtractor: (campaign) => campaign.campaign_id,
                    filterOptions: [],
                    rowClickCallback: campaignClick,
                    actionCreator: actionCreator,
                }}
            />
            <ListPickerModal<Lead>
                title={"Add Lead to Campaign"}
                description={"Send the selected lead to the campaign"}
                client={LeadsClient.defaultClient}
                filters={[]}
                getId={(value: Lead) => value.lead_id}
                mapper={(lead: Lead) =>
                    `${lead.email} - ${lead.first_name} ${lead.last_name}`
                }
                callback={addLeadCallback}
                show={sendLeadCampaignId != null}
            />

            <ListPickerModal<LeadGroup>
                show={sendGroupCampaignId != null}
                title={"Pick a Group"}
                description={"Pick a group to send through this campaign."}
                client={LeadGroupsClient.defaultClient}
                filters={[]}
                getId={(c) => c.lead_group_id}
                mapper={(c) => c.name}
                callback={async (group: LeadGroup | null) => {
                    if (!group) {
                        setSendGroupCampaignId(null);
                        return;
                    }
                    const result = await new CampaignsClient().addLeads(
                        {
                            campaign_id: sendGroupCampaignId!,
                            type: "group_ids",
                            values: [group.lead_group_id],
                        },
                        clientContext,
                    );
                    toastIfError(clientContext, result);
                    if (isSuccess(result)) setSendGroupCampaignId(null);
                }}
            />

            <CampaignDefaultModal
                show={showCampaignDefaultId != null}
                close={() => setShowCampaignDefaultId(null)}
                campaign_id={showCampaignDefaultId!}
            />
            <CampaignEditor
                campaign_id={editId}
                copyCampaign={duplicateCampaign}
                close={() => {
                    setEditId(null);
                    setDuplicateCampaign(null);
                }}
            />
        </PowerPageContent>
    );
}

export default CampaignsPage;
