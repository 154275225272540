import SimplePageContent from "../../components/./simplePage/SimplePageContent";
import * as React from "react";
import {useContext} from "react";
import {
    FieldClass,
    FieldSourceType,
    FieldType, InvitationSchema,
    MaxLength,
    MinLength,
    IModelField,
    ModelSchema,
    Required, getResultOrNull
} from "lib-shared";
import {InvitationsClient} from "lib-client";
import {UserContext} from "../../context/UserContext";
import AppForm, {FormSubmitPayload} from "../../components/forms/AppForm";
import {useAppNav} from "../../hooks/useAppNav";
import {useQueryState} from "../../hooks/useQueryParam";
import {toastIfError} from "../../context/toasts/ToastManager";
import {hardNavToOrg} from "../../utils/Utils";

function SetupJoinOrgPage() {
    const {clientContext} = useContext(UserContext);
    const [urlInviteCode, setUrlInviteCode] = useQueryState(InvitationSchema.InvitationId.name);

    async function acceptInvite(payload: FormSubmitPayload<InviteCodeRequest>): Promise<boolean> {
        const response = await new InvitationsClient().acceptInvitation({id: payload.value.invite_code}, clientContext)

        const result = getResultOrNull(response)
        if (!result) {
            toastIfError(clientContext, response)
            return false
        }

        hardNavToOrg(result)
        return true
    }

    return (
        <SimplePageContent title={"Account Setup"} desc={"Let's finish setting up your Account"}>
            <div className="container mx-auto card p-5 offset-0 col-12 offset-sm-3 col-sm-6">
                <div className="alert alert-info mb-3" role="alert">Enter your Invite Code below. The person who set up
                    your ZenSend account can create an invite and share it with you.
                </div>
                <AppForm
                    onSubmit={acceptInvite}
                    schema={InviteCodeRequestSchema}
                    submitLabel={"Join"}
                    fields={[
                        {
                            fieldType: "text",
                            defaultValue: urlInviteCode ?? undefined,
                            field: InviteCodeRequestSchema.InviteCode
                        }
                    ]}
                />
            </div>

        </SimplePageContent>
    )
}

interface InviteCodeRequest {
    readonly invite_code: string
}

export class InviteCodeRequestSchemaDef extends ModelSchema<InviteCodeRequest> {
    getFriendlyName(value: InviteCodeRequest): string {
        return value.invite_code
    }

    get apiResourceName(): string {
        throw new Error("Method not implemented.");
    }

    get KeyColumns(): IModelField<any, InviteCodeRequest>[] {
        throw new Error("Method not implemented.");
    }

    get SchemaName(): string {
        return "Invite Code Request"
    }

    public readonly InviteCode = new FieldClass<string, InviteCodeRequest>({
        field_type: FieldType.TEXT,
        field_source: FieldSourceType.USER_DEFINED,
        display_name: "Invite Code", name: "invite_code", rules: [
            new Required<InviteCodeRequest>(),
            new MinLength(36),
            new MaxLength(36),
        ]
    })
}

export const InviteCodeRequestSchema: InviteCodeRequestSchemaDef = new InviteCodeRequestSchemaDef()

export const JoinOrgPath = "/join-org"

export default SetupJoinOrgPage