import {EmailProvider, EmailProviderSchema, isSuccess} from "lib-shared";
import * as React from "react";
import {useContext, useState} from "react";
import {UserContext} from "../../context/UserContext";
import PowerPageContent from "../../components/powerPage/PowerPageContent";
import {ColumnSpec, FilterOption} from "../../components/tables/BaseTable";
import ApiTable, {ApiFilter, ApiOrderBy, createColumn} from "../../components/tables/ApiTable";
import IconButton from "../../components/common/IconButton";
import {EmailProvidersClient} from "lib-client";
import {ToastContext, toastIfError} from "../../context/toasts/ToastManager";
import EmailProviderEditorModal from "./EmailProviderEditorModal";
import Button from "react-bootstrap/Button";
import {emailProviderTypeWithIcon} from "../../utils/ReactUtils";
import {ConfirmModalContext} from "../../context/confirmModal/ConfirmModalContext";
import {TableAction} from "../../components/tables/TableActions";

function EmailProvidersPage(props: Props) {
    const {clientContext, appUser} = useContext(UserContext);
    const {toast} = useContext(ToastContext);
    const {confirm} = useContext(ConfirmModalContext);

    const [showModal, setShowModal] = useState(false)

    function addProvider(): void {
        setShowModal(true)
    }

    async function forceRefreshAuthorization(emailProvider: EmailProvider): Promise<void> {
        const client = new EmailProvidersClient()
        const result = await client.forceRefreshAuth({id: emailProvider.email_provider_id}, clientContext)
        if (result.redirectUriForOAuth) {
            window.location.href = result.redirectUriForOAuth
        } else {
            console.error("Expected redirectUriForOAuth from forceRefreshAuth")
        }
    }

    const columns: ColumnSpec<EmailProvider, ApiOrderBy>[] = [
        createColumn(EmailProviderSchema.Email, true),
        createColumn(EmailProviderSchema.Type, true, {valueFormatter: (value, provider) => emailProviderTypeWithIcon(provider.email_provider_type)}),
        {
            name: "Status",
            defaultColumn: true,
            cellExtractor: provider => {
                let issueNode = <span className={"text-success"}>OK</span>

                if (provider.issue_message) {
                    issueNode = <span className={"text-danger"}>{provider.issue_message} <Button size={"sm"}
                                                                                                 onClick={() => forceRefreshAuthorization(provider)}>Fix</Button></span>
                }
                return {
                    value: issueNode
                }
            },
        },
        createColumn(EmailProviderSchema.UpdatedAt, true),
        createColumn(EmailProviderSchema.CreatedAt, false),
    ]


    const filters: FilterOption<ApiFilter>[] = [
        {
            name: "Provider Type",
            filters: [
                {
                    displayName: "Provider Type",
                    displayValue: "Google",
                    field: EmailProviderSchema.Type.name,
                    value: ["google"]
                },
                {
                    displayName: "Provider Type",
                    displayValue: "Microsoft",
                    field: EmailProviderSchema.Type.name,
                    value: ["microsoft"]
                },
                {
                    displayName: "Provider Type",
                    displayValue: "Manual CSV",
                    field: EmailProviderSchema.Type.name,
                    value: ["manual"]
                },
            ]
        },
    ]

    async function test(emailProvider: EmailProvider): Promise<void> {
        const client = new EmailProvidersClient()

        let success = false
        try {
            const result = await client.test({id: emailProvider.email_provider_id}, clientContext)
            toastIfError(clientContext, result)
            success = isSuccess(result)
        } catch (e) {
            console.error(e)
        }

        if (success) {
            toast({
                title: "Success!",
                body: `Successfully tested email provider`,
                icon: "check"
            })
        } else {
            toast({
                title: "Test Failed",
                variant: "danger",
                body: "The test failed. Please try again or contact support.",
                icon: "warning"
            })
        }

    }

    function actionCreator(emailProviders: EmailProvider[]): TableAction[] {
        return [
            {
                icon: "pencil",
                label: "Edit",
                action: () => {
                }
            },
            {
                icon: "play",
                label: "Test",
                action: () => test(emailProviders[0])
            },
            {
                icon: "trash",
                label: "Delete",
                color: "danger",
                supportsMultiRows: true,
                action: () => {
                    confirm({
                        action_type: "delete",
                        client: EmailProvidersClient.defaultClient,
                        resources: emailProviders.map((c) => {
                            return {
                                id: c.email_provider_id,
                                name: c.email!,
                            };
                        }),
                    })
                }
            },
            {
                icon: "arrow-clockwise",
                label: "Force Refresh Auth",
                color: "danger",
                action: () => forceRefreshAuthorization(emailProviders[0])
            },
        ]
    }

    return (
        <PowerPageContent title={"Email Providers"}
                          description={"Email Providers allow us to integrate with your email accounts, send messages on your behalf and track conversations initiated via ZenSend."}
                          buttons={[
                              <IconButton key={"new-provider"} action={addProvider} icon={"plus-circle"}
                                          label={"New Provider"}/>,
                          ]}>
            <ApiTable<EmailProvider> client={EmailProvidersClient.defaultClient}
                                     noDataMessage={"No Email Providers yet."}
                                     tableSettings={{
                                         columns,
                                         idExtractor: ep => ep.email_provider_id,
                                         filterOptions: filters,
                                         actionCreator: actionCreator
                                     }}
            />
            <EmailProviderEditorModal followAppRedirect={true}
                                      show={showModal}
                                      close={() => setShowModal(false)}
                                      callback={(_: EmailProvider) => {
                                      }}
            />
        </PowerPageContent>
    )
}

interface Props {

}

export default EmailProvidersPage