import * as React from "react";
import {ReactNode, useEffect, useState} from "react";
import TableRow from "./TableRow";
import TableFilterItem from "./TableFilterItem";
import TableFilterButton from "./TableFilterButton";
import "./AppTable.css"
import TableHeaderCell from "./TableHeaderCell";
import {OrderByDirection} from "lib-shared";
import TableActions, {TableAction} from "./TableActions";
import TableSettingsDropdown, {ArchiveProps} from "./TableSettingsDropdown";
import LoadingIndicator from "../LoadingIndicator";
import Button from "../common/Button";

function BaseTable<TValue, TFilter extends BaseFilterField, TOrderBy extends BaseOrderBy>(props: Props<TValue, TFilter, TOrderBy>) {
    const settings = props.tableSettings

    const [checkedRowIds, setCheckedRowIds] = useState<string[]>([])
    const [selectedColumns, setSelectedColumns] = useState<string[]>(props.tableSettings.columns.filter(c => c.defaultColumn).map(c => c.name))

    const filterCount = settings.filterOptions?.reduce<number>((count: number, curr) => count + curr.filters.length, 0) ?? 0

    // update filters if some have changed (probably because some were loaded via network call)
    useEffect(() => {
        const defaultFilters = settings.filterOptions
            ?.flatMap(f => f.filters)
            ?.filter(f => f.isDefault)

        if (!defaultFilters || defaultFilters.length == 0) {
            return
        }

        props.setFilters(defaultFilters)
    }, [filterCount]);


    const columns = props.tableSettings.columns
        .filter(c => selectedColumns.includes(c.name))

    // todo memoize
    const rowData: RowData<TValue>[] = props.data.map(d => {
        return {
            value: d,
            id: props.tableSettings.idExtractor(d),
            cells: columns.map(c => c.cellExtractor(d))
        }
    })


    // if ids changed we need to filter out all the ids that are no longer on the page
    // happens if filters or order changed or if a new row was added
    useEffect(() => {
        // only include ids that are still on the page
        setCheckedRowIds(checkedRowIds.filter(id => rowData.some(d => d.id == id)))
    }, [rowData.map(d => d.id).join(" ")])

    function isChecked(id: string): boolean {
        return checkedRowIds.includes(id)
    }

    const checkedRows: RowData<TValue>[] = rowData.filter(row => isChecked(row.id))

    function addFilter(filterField: TFilter) {
        props.setFilters([...props.filters, filterField])
    }

    function removeFilter(filterField: TFilter) {
        const filters = props.filters.filter(f => {
            return !(f.displayName == filterField.displayName && f.displayValue == filterField.displayValue)
        })
        props.setFilters(filters)
    }


    function toggleChecked(id: string): void {
        if (isChecked(id)) {
            setCheckedRowIds(checkedRowIds.filter(checkedId => checkedId != id))
        } else {
            setCheckedRowIds([...checkedRowIds, id])
        }
    }

    function toggleCheckedAll() {
        const allChecked = rowData.length <= checkedRowIds.length
            && rowData.every(row => isChecked(row.id))

        if (allChecked) {
            setCheckedRowIds([])
        } else {
            setCheckedRowIds(rowData.map(row => row.id))
        }
    }

    const checkedRowActions: TableAction[] = checkedRows.length > 0 && props.tableSettings.actionCreator ?
        props.tableSettings.actionCreator(checkedRows.map(row => row.value))
            .filter(action => action.supportsMultiRows) : []

    const hasFilters = (settings.filterOptions ?? []).length > 0 || props.filters.length > 0

    return (
        <>
            {!props.tableSettings.small &&
                <div className={"mb-2"}>
                    <div className="d-flex gap-2 py-3 px-7 border-bottom">
                        {hasFilters &&
                            <>
                                {settings.filterOptions.map(option => {
                                    return <TableFilterButton key={"filterButton" + option.name}
                                                              option={option}
                                                              addFilter={addFilter}/>
                                })}
                                <div>
                                    {props.filters.map((filter) => {
                                        return <TableFilterItem key={filter.displayName + filter.displayValue}
                                                                filter={filter}
                                                                removeFilter={removeFilter}/>
                                    })}
                                </div>
                            </>
                        }
                        <div className={"flex-fill"}></div>
                        <div className={"mt-3 " + (props.loading ? "" : "d-none")}><LoadingIndicator small={true}/>
                        </div>
                        {hasFilters &&
                            <div
                                className="align-items-center ms-auto text-sm text-muted text-primary-hover fw-semibold d-none d-md-flex"
                                onClick={() => props.setFilters([])}
                                role="button"><i className="bi bi-x me-1"></i> <span>Clear filters</span>
                            </div>
                        }
                        <TableSettingsDropdown
                            {...props}
                            setIsAutoRefresh={props.setIsAutoRefresh}
                            selectedColumns={selectedColumns}
                            setSelectedColumns={setSelectedColumns}
                            isAutoRefresh={props.isAutoRefresh}
                            columns={props.tableSettings.columns}
                        />
                    </div>
                </div>
            }
            <div className="border-top">
                <table
                    className={`table table-hover table-striped table-nowrap app-table ${props.tableSettings.small ? "table-sm" : ""}`}>
                    <thead>

                    <tr>
                        {props.tableSettings.actionCreator &&
                            <th className={``} scope="col">
                                <input type={"checkbox"}
                                       checked={checkedRowIds.length == rowData.length && rowData.length > 0}
                                       onChange={() => {
                                       }}
                                       onClick={toggleCheckedAll}/>
                            </th>}
                        {columns.map((col, index) => {
                            return <TableHeaderCell key={index} column={col} order={props.order}
                                                    setOrderBy={props.setOrder}/>
                        })}
                        {
                            // add another column if we have actions to show
                            checkedRowActions.length > 0 &&
                            <th className={`w-md-32 text-end`} scope="col">
                                <TableActions actions={checkedRowActions}/>
                            </th>
                        }
                        {
                            // add another column if we have actions to show
                            (props.tableSettings.actionCreator && checkedRowIds.length == 0) &&
                            <th className={`w-md-32`} scope="col"></th>
                        }
                    </tr>
                    </thead>
                    <tbody>
                    {rowData.map((row, index) => {
                        return <TableRow key={index} data={row} rowNum={index}
                                         tableSettings={props.tableSettings}
                                         showCheckbox={props.tableSettings.actionCreator != null}
                                         isChecked={isChecked(row.id)} toggleChecked={toggleChecked}/>
                    })}
                    </tbody>
                </table>
            </div>
        </>
    )
}

export interface OrderByState<T extends BaseOrderBy> {
    readonly orderBy: T
    readonly dir: OrderByDirection
}

export interface Props<TValue, TFilter extends BaseFilterField, TOrderBy extends BaseOrderBy> extends ArchiveProps {
    readonly tableSettings: TableSettings<TValue, TFilter, TOrderBy>
    readonly loading: boolean
    readonly order: OrderByState<TOrderBy> | undefined
    readonly setOrder: (order: OrderByState<TOrderBy>) => void
    readonly isAutoRefresh: boolean | null
    readonly setIsAutoRefresh: (value: boolean) => void
    readonly filters: TFilter[]
    readonly setFilters: (filters: TFilter[]) => void
    readonly data: TValue[]
    readonly exportCsv?: (columns: ColumnSpec<TValue, TOrderBy>[]) => void
}

export interface BaseFilterField {
    readonly  displayName: string
    readonly  displayValue: string
    readonly  isDefault?: boolean
}

export interface TableSettings<TValue, TFilter extends BaseFilterField, TOrderBy extends BaseOrderBy> {
    readonly small?: boolean
    readonly idExtractor: (value: TValue) => string
    readonly columns: ColumnSpec<TValue, TOrderBy>[]
    readonly filterOptions: FilterOption<TFilter>[]
    readonly rowClickCallback?: (value: TValue) => void
    // value will never be empty
    readonly actionCreator?: (value: TValue[]) => TableAction[]
}

export interface FilterOption<TFilter extends BaseFilterField> {
    readonly name: string
    readonly filters: TFilter[]
}

export interface BaseOrderBy {
}

export type ColumnSize = "sm" | "lg" | "xl"

export interface ColumnSpec<TValue, TOrderBy extends BaseOrderBy> {
    readonly name: string
    readonly defaultColumn: boolean
    readonly orderBy?: TOrderBy
    readonly size?: ColumnSize
    readonly cellExtractor: (value: TValue) => TableCell
}

export interface RowData<T> {
    readonly value: T
    readonly id: string
    readonly cells: TableCell[]
}

export interface TableCell {
    readonly value: string | ReactNode
    readonly config?: TableCellConfig
}

export interface TableCellConfig {
    readonly color?: "text-success" | "text-danger" | "text-muted"
    readonly weight?: "fw-semibold"
}

export default BaseTable