import {useContext} from "react";
import {useParams} from "react-router-dom";
import {UserContext} from "../../context/UserContext";
import {
    EntitySentiment,
    EntityType,
    isSuccess,
    SocialMediaPlatform,
    SocialMediaPostKey,
    SocialMediaProfile,
    TikTokPost,
    TikTokPostEntity,
    TikTokPostEntitySchema
} from "lib-shared";
import {useApiGet} from "../../hooks/useApiGet";
import {SocialMediaProfilesClient, TikTokPostEntitiesClient, TikTokPostsClient} from "lib-client";
import ApiResultBlock from "../../components/ApiResultBlock";
import ApiTable, {ApiOrderBy, createColumn} from "../../components/tables/ApiTable";
import PowerPageContent from "../../components/powerPage/PowerPageContent";
import {ColumnSpec} from "../../components/tables/BaseTable";
import MaterialIcon from "../../components/common/MaterialIcon";
import {Col, Row} from "react-bootstrap";
import SimpleTextStat from "../../components/SimpleTextStat";
import {makeProfileLink} from "./SocialMediaProfilePage";
import {useAppNav} from "../../hooks/useAppNav";
import IconButton from "../../components/common/IconButton";
import {toastResult} from "../../context/toasts/ToastManager";
import ZLink from "../../components/common/ZLink";
import ProfileAvatarIcon from "../../components/common/ProfileAvatarIcon";

function TikTokPostPage() {

    const nav = useAppNav()
    const {clientContext} = useContext(UserContext)
    const params = useParams();
    const platform = params["platform"]
    const username = params["username"]
    const postId = params["postId"]

    if (!username || !platform || !postId) {
        nav("/social/posts")
        return <></>
    }

    const profileResult = useApiGet<SocialMediaProfile>(clientContext, SocialMediaProfilesClient.defaultClient, username)
    const postResult = useApiGet<TikTokPost>(clientContext, TikTokPostsClient.defaultClient, postId)

    const columns: ColumnSpec<TikTokPostEntity, ApiOrderBy>[] = [
        createColumn(TikTokPostEntitySchema.Username, false),
        createColumn(TikTokPostEntitySchema.Id, false),
        createColumn(TikTokPostEntitySchema.Entity, true),
        createColumn(TikTokPostEntitySchema.EntityType, true, {
            valueFormatter: (value, row) => {
                switch (row.entity_type) {
                    case EntityType.ORGANIZATION:
                        return <><MaterialIcon icon={"building"} size={14} margin={2}/>Organization</>
                    case EntityType.PERSON:
                        return <><MaterialIcon icon={"person"} size={14} margin={2}/>Person</>
                    case EntityType.OTHER:
                        return <><MaterialIcon icon={"question"} size={14} margin={2}/>Other</>
                }
            }
        }),
        createColumn(TikTokPostEntitySchema.EntitySentiment, true, {
            valueFormatter: (value, row) => {
                switch (row.entity_sentiment) {
                    case EntitySentiment.NEUTRAL:
                        return <span className={"text-muted"}><MaterialIcon icon={"emoji-neutral"} size={14}
                                                                            margin={2}/>Neutral</span>
                    case EntitySentiment.POSITIVE:
                        return <span className={"text-success"}><MaterialIcon icon={"emoji-smile"} size={14}
                                                                              margin={2}/>Positive</span>
                    case EntitySentiment.NEGATIVE:
                        return <span className={"text-danger"}><MaterialIcon icon={"emoji-frown"} size={14} margin={2}/>Negative</span>
                }
            }
        }),
    ]

    async function analyzePost() {
        const result = await TikTokPostsClient.defaultClient.enrichPost({
            ...postResult.value!,
            platform: SocialMediaPlatform.TIKTOK
        }, clientContext)
        toastResult(result, clientContext)
    }

    async function refresh() {
        const result = await TikTokPostsClient.defaultClient.refresh({
            platform: SocialMediaPlatform.TIKTOK,
            username: username!,
            post_id: postId!
        }, clientContext)
        toastResult(result, clientContext)
        // refresh data
        if (isSuccess(result))
            postResult.rerun()
    }

    return (
        <PowerPageContent title={"Post"}
                          icon={"tiktok"}
                          parentHref={makeProfileLink(profileResult.value)}
                          buttons={[
                              <IconButton key={"refresh"} action={refresh}
                                          icon={"arrow-clockwise"}
                                          label={"Refresh"}/>
                          ]}
        >
            <ApiResultBlock result={postResult}>
                {(value) =>
                    (<>
                            <Row>
                                <div className={"card p-5 mb-5"} style={{maxWidth: "800px"}}>
                                    <div className={"d-flex"}>
                                        <div className={"flex-column"}>
                                            <a href={`https://www.tiktok.com/${postResult.value!.username}/video/${postResult.value!.post_id}`}
                                               target={"_blank"}>
                                                <img className={"rounded"}
                                                     src={value.thumbnail_uri ?? "/media.png"} alt={"avatar for use"}
                                                     style={{maxWidth: "300px", maxHeight: "300px"}}/>
                                            </a>
                                        </div>
                                        <div className={"flex-column ms-5"}>
                                            <div className={"flex-column"}>
                                                <a href={`https://tiktok.com/${username}`} target={"_blank"}>
                                                    <ProfileAvatarIcon src={profileResult.value?.avatar_uri}
                                                                       style={"rounded-circle"} size={80}/>
                                                </a>
                                            </div>
                                            <div className={"flex-column m-3"}>
                                                <h3>{profileResult.value?.username}</h3>
                                                <div>{profileResult.value?.nickname}</div>
                                            </div>
                                            <p className={"lead mt-3"}>{value.description}</p>
                                        </div>
                                    </div>
                                </div>
                            </Row>
                            <Row>
                                <Col><SimpleTextStat value={new Date(value.create_time)}
                                                     label={"Posted"}/></Col>
                                <Col><SimpleTextStat value={value.collect_count} label={"Collects"}/></Col>
                                <Col><SimpleTextStat value={value.comment_count} label={"Comments"}/></Col>
                            </Row>
                            <Row>
                                <Col><SimpleTextStat value={value.digg_count} label={"Diggs"}/></Col>
                                <Col><SimpleTextStat value={value.play_count} label={"Views"}/></Col>
                                <Col><SimpleTextStat value={value.share_count} label={"Shares"}/></Col>
                            </Row>
                            <div className={"mt-3 mb-3"} style={{maxWidth: "700px"}}>
                                {value.video_summary &&
                                    <div className={"alert alert-primary"}>
                                        <b><MaterialIcon icon={"cpu"} size={18} margin={1}/> Analysis</b>
                                        <p>{value.video_summary}</p></div>}
                                {!value.video_summary &&
                                    <div className={"alert alert-warning"}>Not enriched yet. <IconButton key={"analyze"}
                                                                                                         action={analyzePost}
                                                                                                         icon={"cpu"}
                                                                                                         label={"Analyze"}/>
                                    </div>}
                            </div>
                        </>
                    )}
            </ApiResultBlock>
            <h3 className={"mt-5"}>Post Sentiment</h3>
            <p className={"text-muted text-sm"}>We analyze the post an extract the sentiment about various people and
                companies that are mentioned.</p>
            <ApiTable<TikTokPostEntity> client={TikTokPostEntitiesClient.defaultClient}
                                        noDataMessage={"This post hasn't been analyzed yet."}
                                        listOptions={{
                                            filterFields: [
                                                {
                                                    field: TikTokPostEntitySchema.Username.name,
                                                    value: [username]
                                                },
                                                {
                                                    field: TikTokPostEntitySchema.PostId.name,
                                                    value: [postId]
                                                },
                                            ]
                                        }}
                                        tableSettings={{
                                            idExtractor: (post: TikTokPostEntity) => `${post.username}#${post.post_id}#${post.id}`,
                                            filterOptions: [],
                                            columns: columns,
                                        }}
            />
        </PowerPageContent>
    )
}

export function createPostPageLink(post: SocialMediaPostKey): string {
    return `/social/posts/${post?.platform}/${post?.username}/${post?.post_id}`
}

export default TikTokPostPage