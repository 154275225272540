import {useRouteError} from "react-router-dom";

function ErrorPage() {
    let error = useRouteError();
    console.error(error);
    // Uncaught ReferenceError: path is not defined
    return (
        <div
            className="d-flex flex-column justify-content-center align-items-center vh-100 overflow-y-auto gradient-bottom-right start-indigo middle-yellow end-purple">
            <div className="container-xxl">
                <div className="row justify-content-center align-items-center">
                    <div className="col-lg-6 text-center h-100 order-lg-2">
                        <img src="./ill-1.svg" alt="..."/>
                    </div>
                    <div className="col-lg-4 order-lg-1">
                        <div className=""><h1 className="display-1 mb-4">Ooops!</h1><p className="lead lh-relaxed">The
                            page you are looking for could not be found.</p>
                            <div className="my-10"><a href="/" className="btn btn-dark">Return
                                Home</a></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
)
}

export default ErrorPage