import {ApiResponse, Draft, DraftSchema, GenericItemRequest, IDraftsService, ModelSchema} from "lib-shared";
import {BaseCrudClient} from "./BaseCrudClient";
import {ClientContext, sendApi} from "../FetchUtils";

export class DraftsClient extends BaseCrudClient<Draft> implements IDraftsService<ClientContext> {
    public static readonly defaultClient: DraftsClient = new DraftsClient()
    public get schema(): ModelSchema<Draft> {
        return DraftSchema
    }

    regenerate(req: GenericItemRequest, ctx: ClientContext): Promise<ApiResponse<void>> {
        return sendApi(`/${this.resourceName}/${req.id}/regenerate`, "POST", null, ctx);
    }

    send(req: GenericItemRequest, ctx: ClientContext): Promise<ApiResponse<void>> {
        return sendApi(`/${this.resourceName}/${req.id}/send`, "POST", null, ctx);
    }
}