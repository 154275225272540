import {createUserWithEmailAndPassword, getAuth,} from 'firebase/auth';
import {Link} from 'react-router-dom'
import {useAppNav} from "../../hooks/useAppNav";
import GoogleSignin from "../signin/GoogleSignin";
import SimplePageContent from "../../components/./simplePage/SimplePageContent";
import AppForm, {FormSubmitPayload} from "../../components/forms/AppForm";
import {
    CharacterSet,
    CharacterSetType,
    FieldClass,
    FieldSourceType,
    FieldType,
    IModelField,
    MinLength,
    ModelSchema,
    Required
} from "lib-shared";
import {FormField} from "../../components/forms/FormFields";
import {UserContext} from "../../context/UserContext";
import {useContext} from 'react';

function Register() {
    const {fbUser, loadingAppUser, appUser} = useContext(UserContext);

    const navigate = useAppNav()

    if (!loadingAppUser && fbUser) {
        navigate("/")
        return <></>
    }


    async function handleSubmit(req: FormSubmitPayload<Credentials>): Promise<boolean> {
        const auth = getAuth();
        try {
            // Send the email and password to firebase
            const userCredential = await createUserWithEmailAndPassword(auth, req.value.email, req.value.password)

            if (userCredential) {
                navigate('/settings')
            } else {
                return false
            }
        } catch (error: any) {
            console.log('User Create Failed', error.message);
            return false
        }
        return true
    }


    return (
        <SimplePageContent title={"Sign Up"} desc={"Sign up for Zensend and start personalizing your sales at scale."}>
            <div className="container mx-auto shadow m-5 p-5 rounded bg-light offset-0 col-12 offset-sm-4 col-sm-4">
                <div className={"p-3"}>
                    <div className={"row"}>
                        <GoogleSignin/>
                    </div>
                </div>
                <div className="m-3 gap-2 col-4 mx-auto border-bottom"/>
                <div className={"text-center m-4"}>Or use email and password</div>
                <AppForm submitLabel={"Register"}
                         onSubmit={handleSubmit}
                         schema={credentialsSchema}
                         fields={credentialsFields}

                />

                <div className="m-3 gap-2 col-4 mx-auto border-bottom"/>

                <div className={"row mb-3 mt-3"}>
                    <Link to={"/signin"}>Already have an account?</Link>
                </div>
            </div>
        </SimplePageContent>
    )
}

export interface Credentials {
    readonly email: string
    readonly password: string
}

export class CredentialsSchemaDef extends ModelSchema<Credentials> {
    get apiResourceName(): string {
        throw new Error('Method not implemented.');
    }
    get KeyColumns(): IModelField<any, Credentials>[] {
        throw new Error('Method not implemented.');
    }
    getFriendlyName(value: Credentials): string {
        throw new Error('Method not implemented.');
    }
    get SchemaName(): string {
        return "Credentials"
    }

    public readonly Email = new FieldClass<string, Credentials>({
        display_name: "Email", name: "email",
        field_source: FieldSourceType.USER_DEFINED,
        field_type: FieldType.TEXT,
        rules: [
            new Required<Credentials>(),
            new CharacterSet(CharacterSetType.Email)
        ]
    })
    public readonly Password = new FieldClass<string, Credentials>({
        field_source: FieldSourceType.USER_DEFINED,
        field_type: FieldType.TEXT,
        display_name: "Password", name: "password", rules: [
            new Required<Credentials>(),
            new MinLength<Credentials>(8),
        ]
    })
}

export const credentialsSchema: CredentialsSchemaDef = new CredentialsSchemaDef()

export const credentialsFields: FormField<Credentials>[] = [
    {
        fieldType: "text",
        stringType: "email",
        field: credentialsSchema.Email,
    },
    {
        fieldType: "text",
        stringType: "password",
        field: credentialsSchema.Password,
    }
]
export default Register
