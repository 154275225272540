import {
    ApiResponse,
    IUsersService,
    ModelSchema,
    SetUpUserRequest,
    SetUpUserResponse,
    User,
    UserSchema
} from "lib-shared";
import {ClientContext, sendApi} from "../FetchUtils";
import {BaseCrudClient} from "./BaseCrudClient";

export class UsersClient extends BaseCrudClient<User> implements IUsersService<ClientContext> {
    public static readonly defaultClient: UsersClient = new UsersClient()

    get schema(): ModelSchema<User> {
        return UserSchema;
    }

    setup(req: SetUpUserRequest, ctx: ClientContext): Promise<ApiResponse<SetUpUserResponse>> {
        return sendApi(`/${this.resourceName}/setUp`, "POST", req, ctx)
    }
}
