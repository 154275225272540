import * as React from "react";
import {useContext, useState} from "react";
import {ApiErrorCode, Lead, LeadSchema, validate, BulkUploadLead, getDuplicates} from "lib-shared";
import PowerPageContent from "../../../components/powerPage/PowerPageContent";
import BulkAddLeadsTable from "./BulkAddLeadsTable";
import Button from "../../../components/common/Button";
import {v4 as uuidv4} from "uuid"
import SlimAttribute from "../../../components/metrics/SlimAttribute";
import CsvLeadImporter from "./CsvLeadImporter";
import {ToastContext} from "../../../context/toasts/ToastManager";
import BulkUploadModal from "./BulkUploadModal";
import BulkUploadLeadEditor from "./BulkUploadLeadEditor";
import {UserContext} from "../../../context/UserContext";

function BulkAddLeadsPage() {
    const toastProps = useContext(ToastContext)
    const {clientContext, appUser} = useContext(UserContext);


    const [showUpload, setShowUpload] = useState<boolean>(false)
    const [leads, setLeads] = useState<StagedBulkUploadLead[]>([])
    const [editLead, setEditLead] = useState<StagedBulkUploadLead | null>(null)

    const validLeads = leads.filter(l => validate(l.lead, LeadSchema).isValid)

    const duplicates = getDuplicates(leads, (v) => v.lead.lead_id)
    const duplicateCount = duplicates.length

    const invalidCount = leads.length - validLeads.length

    function addLead(): void {
        const lead: Lead = {
            org_id: "",
            lead_id: "",
            email: "",
            is_researched: false,
            owner: appUser!.user_id
        }

        setEditLead({
            id: uuidv4(),
            lead,
            fields: []
        })
    }

    function addLeads(newLeads: StagedBulkUploadLead[]): void {
        if (newLeads.length == 0) {
            toastProps.toast({
                title: "Import Failed",
                icon: "warning",
                body: `No leads imported!`
            })
            return
        }

        setLeads([...leads, ...newLeads])

        toastProps.toast({
            title: "Leads Imported",
            icon: "task_alt",
            body: `Imported ${newLeads.length} leads`
        })
    }

    function closeLeadEditor() {
        setEditLead(null)
    }

    function onLeadEditorSubmit(value: StagedBulkUploadLead): Promise<boolean> {
        console.log(`updating lead ${editLead!.lead.lead_id}...`)
        const original = leads.findIndex(l => l.lead.lead_id == editLead!.lead.lead_id)
        if (original == -1) {
            setLeads([...leads, value])
        } else {
            const updatedArray = [...leads]
            updatedArray[original] = value
            setLeads(updatedArray)
        }

        setEditLead(null)
        return Promise.resolve(true)
    }

    function deleteLeads(leadsToDelete: StagedBulkUploadLead[]): void {
        const deleteIds = leadsToDelete.map(l => l.id)
        setLeads(leads.filter(l => !deleteIds.includes(l.id)))
    }

    function upload() {
        if (invalidCount > 0) {
            toastProps.toast({
                title: `${invalidCount} Lead Errors`,
                icon: "warning",
                variant: "danger",
                body: `Please fix or delete all invalid leads before uploading.`,
            })
            return
        }

        if (duplicateCount > 0) {
            toastProps.toast({
                title: `${duplicateCount} Duplicate Leads`,
                icon: "warning",
                variant: "danger",
                body: `Please resolve all duplicates before uploading.`,
            })
            return
        }

        if (leads.length == 0) {
            toastProps.toast({
                title: `No Leads`,
                icon: "warning",
                variant: "danger",
                body: `There are no leads to upload.`,
            })
            return
        }

        setShowUpload(true)
    }

    return (
        <PowerPageContent title={"Bulk Lead Upload"}
                          buttons={[
                              <CsvLeadImporter key={"csv-upload"} addLeads={addLeads}/>,
                              <Button key={"add-lead"} onClick={addLead} variant={"outline-primary"} label={"Add Lead"}
                                      icon={"plus"}/>,
                              <Button key={"upload"} onClick={upload} variant={"primary"} label={"Finish & Upload"}
                                      icon={"cloud-upload"}/>,
                          ]}
                          description={"Bulk add Leads by uploading a CSV file."}>
            <div className={"row"}>
                <div className={"d-flex justify-content-start"}>
                    <SlimAttribute
                        label={`${invalidCount} leads have errors`} color={invalidCount > 0 ? "danger" : "success"}
                        icon={invalidCount > 0 ? "warning" : "check"}/>
                    <SlimAttribute
                        label={`${duplicateCount} duplicates`} color={duplicateCount > 0 ? "danger" : "success"}
                        icon={duplicateCount > 0 ? "warning" : "check"}/>
                </div>
            </div>
            <BulkAddLeadsTable leads={leads}
                               onLeadClicked={(lead) => setEditLead(lead)} deleteLeads={deleteLeads}/>

            <BulkUploadModal show={showUpload} onHide={() => setShowUpload(false)} bulkUploadLeads={leads}/>

            {editLead &&
                <BulkUploadLeadEditor value={editLead} setValue={onLeadEditorSubmit} close={() => setEditLead(null)}/>}
        </PowerPageContent>
    )
}

export interface StagedBulkUploadLead extends BulkUploadLead {
    // globally unique uuid - we don't use lead_id because that's the email which can be duplicated in upload batches
    id: string
}


export default BulkAddLeadsPage