import {useContext, useState} from "react";
import {UserContext} from "../../context/UserContext";
import {useAppNav} from "../../hooks/useAppNav";
import {OrganizationsClient} from "lib-client";
import {ColumnSpec, RowData} from "../../components/tables/BaseTable";
import {Campaign, CampaignSchema, isSuccess, Organization, OrganizationSchema} from "lib-shared";
import Button from "react-bootstrap/Button";
import ApiTable, {ApiOrderBy, createColumn} from "../../components/tables/ApiTable";
import Form from "react-bootstrap/Form";
import {blockForm} from "../../utils/Utils";
import * as React from "react";
import {toastIfError} from "../../context/toasts/ToastManager";
import OrganizationPage from "../settings/OrganizationPage";

function AdminOrg(props: Props) {

    const {fbUser, clientContext, appUser} = useContext(UserContext);

    const nav = useAppNav()

    const [orgName, setOrgName] = useState("")

    async function orgSubmit() {
        const response = await new OrganizationsClient().create({
            org_id: "",
            enable_third_party_data_enrichment: false,
            name: orgName,
        }, clientContext)
        toastIfError(clientContext, response)

        if (isSuccess(response))
            nav("/admin")
    }

    const columns: ColumnSpec<Organization, ApiOrderBy>[] = [
        createColumn(OrganizationSchema.Name, true),
        createColumn(OrganizationSchema.OrgId, true),
        createColumn(OrganizationSchema.CreatedAt, true),
        createColumn(OrganizationSchema.UpdatedAt, false),
    ];

    function mapper(org: Organization): RowData<Organization> {
        return {
            value: org,
            id: org.org_id,
            cells: [
                {
                    value: org.name,
                    config: {weight: "fw-semibold"}
                },
                {
                    value: org.org_id
                },
                {
                    value: <Button onClick={async () => {
                        await new OrganizationsClient().adminJoin({
                            org,
                            user: appUser!,
                            mode: "invite",
                            invitation: null,
                        }, clientContext)
                    }}>Join</Button>
                }
            ]
        }
    }

    return (
        <div>
            <h2>Organizations</h2>
            <ApiTable<Organization> client={OrganizationsClient.defaultClient}
                                    noDataMessage={"No Orgs yet."}
                                    tableSettings={{
                                        columns,
                                        idExtractor: (org: Organization) => org.org_id,
                                        filterOptions: [],
                                    }}
                                    admin={true}
            />
            <Form onSubmit={blockForm(orgSubmit)}>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label>Name</Form.Label>
                    <Form.Control type="text" placeholder="New Org" value={orgName}
                                  onChange={(e) => setOrgName(e.target.value)}/>
                </Form.Group>
                <Button variant="primary" type={"submit"}>
                    Add Group
                </Button>
            </Form>

        </div>
    )
}

interface Props {

}

export default AdminOrg