import * as powersection from "./PowerNavSection";
import PowerNavSection from "./PowerNavSection";
import { UserContext } from "../../context/UserContext";
import { useContext } from "react";
import NavbarUser from "./NavbarUser";
import { fixHref } from "../../utils/Utils";
import { Link } from "react-router-dom";

function PowerNav(props: Props) {
  const { fbUser, clientContext, appUser } = useContext(UserContext);

  const nav: powersection.Props[] = [
    {
      name: "Dashboard",
      icon: "speedometer",
      links: [
        {
          label: "Overview",
          href: "/",
        },
      ],
    },
    {
      name: "Outbox",
      icon: "envelope-fill",
      links: [
        {
          label: "Outbox",
          href: "/outbox",
        },
      ],
    },
    {
      name: "Prospecting",
      icon: "binoculars-fill",
      links: [
        {
          label: "Prospecting",
          href: "/prospecting",
        },
      ],
    },
    {
      name: "Campaigns",
      icon: "megaphone-fill",
      links: [
        {
          label: "Campaigns",
          href: "/campaigns",
        },
      ],
    },
    {
      name: "Leads",
      icon: "person-lines-fill",
      links: [
        {
          label: "Leads",
          href: "/leads",
        },
      ],
    },
    {
      name: "Groups",
      icon: "people-fill",
      links: [
        {
          label: "Groups",
          href: "/groups",
        },
      ],
    },
    {
      name: "Social Media",
      icon: "radar",
      links: [
        {
          label: "Profiles",
          href: "/social/profiles",
        },
        {
          label: "TikTok Posts",
          href: "/social/posts",
        },
      ],
    },
    {
      name: "Segmentation",
      icon: "collection-fill",
      links: [
        {
          label: "Segments",
          href: "/segmentation/classes",
        },
        {
          label: "Jobs",
          href: "/segmentation/jobs",
        },
      ],
    },
    {
      name: "Settings",
      icon: "gear-fill",
      links: [
        {
          label: "Account",
          href: "/settings",
        },
        {
          label: "Sender Profiles",
          href: "/settings/profiles",
        },
        {
          label: "First Party Fields",
          href: "/firstPartyFields",
        },
        {
          label: "Email Providers",
          href: "/settings/providers",
        },
        {
          label: "Organization",
          href: "/settings/organization",
        },
        {
          label: "Users",
          href: "/settings/users",
        },
        {
          label: "Invitations",
          href: "/settings/invitations",
        },
      ],
    },
  ];

  return (
    <nav
      className="navbar navbar-vertical navbar-expand-lg navbar-light show vh-lg-100 flex-none bg-transparent px-0 py-2"
      id="sidebar"
    >
      <div className="container-fluid px-md-4 px-lg-6 px-3">
        <button
          className="navbar-toggler ms-n2 collapsed"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#sidebarCollapse"
          aria-controls="sidebarCollapse"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <Link
          className="navbar-brand d-inline-block py-lg-1 mb-lg-5"
          to={fixHref("/")}
        >
          <img
            src="/logo.png"
            className="logo-dark h-rem-8 h-rem-md-10"
            alt="..."
          />
        </Link>
        <div className="navbar-user d-lg-none">
          <NavbarUser />
        </div>
        <div
          className="navbar-collapse collapse overflow-x-hidden"
          id="sidebarCollapse"
        >
          <ul className="navbar-nav">
            {nav.map((n) => {
              return (
                <li key={n.name} className="nav-item my-1">
                  <PowerNavSection {...n} />
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </nav>
  );
}

interface Props {}

export default PowerNav;
