import * as React from "react";
import {useContext} from "react";
import {getResultOrNull, InvitationSchema, Organization_Id, User, UserSchema} from "lib-shared";
import {UserContext} from "../../context/UserContext";
import {ToastContext, toastIfError} from "../../context/toasts/ToastManager";
import {UsersClient} from "lib-client";
import {useAppNav} from "../../hooks/useAppNav";
import {SetupOrgPath} from "./SetupCreateOrgPage";
import SimplePageContent from "../../components/./simplePage/SimplePageContent";
import AppForm, {FormSubmitPayload} from "../../components/forms/AppForm";
import {getQueryParam, hardNavToOrg} from "../../utils/Utils";
import {JoinOrgPath} from "./SetupJoinOrgPage";
import app from "../../App";
import AppNavigate from "../../hooks/AppNavigate";

function SetupUser(props: Props) {
    const {fbUser, clientContext, setAppUser, appUser} = useContext(UserContext);
    const nav = useAppNav()


    if (appUser) {
        return <AppNavigate to={"/"}/>
    }

    function getName(): [string | undefined, string | undefined] {
        if (fbUser!.displayName) {
            const names = fbUser!.displayName.split(" ")

            if (names.length > 1)
                return [names[0], names.slice(1).join(" ")]
            else
                return [names[0], undefined]
        }
        return [undefined, undefined]
    }

    async function create(payload: FormSubmitPayload<User>): Promise<boolean> {
        const user: User = {
            ...payload.value,
            user_id: fbUser!.uid,
            email: fbUser!.email!,
        }

        const response = await new UsersClient().create(user, clientContext)
        const createdUser = getResultOrNull(response)
        if (!createdUser) {
            toastIfError(clientContext, response)
            return false
        }

        setAppUser(createdUser)

        const invitation_id = getQueryParam(InvitationSchema.InvitationId.name)

        if (invitation_id) {
            console.log(`User created! Invite code ${invitation_id} detected. Navigating invite page`)
            nav(JoinOrgPath, {keepParams: [InvitationSchema.InvitationId.name], dropParams: [Organization_Id]})
        } else {
            console.log(`User created! Navigating to org creation page`)
            nav(SetupOrgPath, {dropParams: [Organization_Id]})
        }
        return true
    }

    const [fn, ln] = getName()

    return (
        <SimplePageContent title={"Account Setup"} desc={"Let's finish setting up your Account"}>
            <AppForm onSubmit={create}
                     schema={UserSchema}
                     fields={[
                         [
                             {
                                 fieldType: "text",
                                 field: UserSchema.FirstName,
                                 defaultValue: fn
                             },
                             {
                                 fieldType: "text",
                                 field: UserSchema.LastName,
                                 defaultValue: ln
                             }
                         ]
                     ]}/>
        </SimplePageContent>
    )
}

export const SetupUserPath = "/setup-user"

interface Props {

}

export default SetupUser