import {UserContext} from "../../context/UserContext";
import {eventBus} from "../../utils/EventBus";
import AppModal from "../../components/AppModal";
import AppForm, {FormSubmitPayload} from "../../components/forms/AppForm";
import {useApiList} from "../../hooks/useApiList";
import {useContext} from "react";
import {isSuccess, LeadGroup, SegmentationClass, SegmentationJob, SegmentationJobSchema,} from "lib-shared";

import {LeadGroupsClient, SegmentationClassClient, SegmentationJobsClient} from "lib-client";
import {Modal} from "react-bootstrap";
import {useApiGet} from "../../hooks/useApiGet";
import {toastIfError} from "../../context/toasts/ToastManager";
import {FormValueSelectItem} from "../../components/forms/FormFields";
import {uuidv4} from "@firebase/util";

function SegmentationJobEditorModal(props: Props) {
    const {clientContext} = useContext(UserContext);

    const {show, close} = props;
    const segmentationClasses = useApiList<SegmentationClass>(
        clientContext,
        SegmentationClassClient.defaultClient,
        {},
        {},
    );

    const segmentationClassOptions: FormValueSelectItem[] = (
        segmentationClasses.value ?? []
    ).map((cls) => {
        return {
            id: cls.class_id,
            label: `${cls.name}`,
        };
    });

    const initialFieldValue = useApiGet<SegmentationJob>(
        clientContext!,
        SegmentationJobsClient.defaultClient,
        props.edit_id ?? "",
    );

    const groups = useApiList<LeadGroup>(clientContext, LeadGroupsClient.defaultClient, {pageSize: 100}, {})

    const allGroups = (groups.value ?? [])
    const groupsOptions: FormValueSelectItem[] =
        [
            {
                id: "",
                label: ""
            },
            ...allGroups.map(group => {
                return {
                    id: group.lead_group_id,
                    label: group.name
                }
            })
        ]

    const cancel = () => {
        close();
    };

    async function submit(
        payload: FormSubmitPayload<SegmentationJob>,
    ): Promise<boolean> {
        const segmentationJob: SegmentationJob = {
            ...payload.value,
            lead_group_id: payload.value.lead_group_id != "" ? payload.value.lead_group_id : undefined,
            state: "pending",
            job_id: uuidv4(),
            org_id: "",
        };

        const response = await new SegmentationJobsClient().create(
            segmentationJob,
            clientContext!,
        )

        if (!isSuccess(response)) {
            toastIfError(clientContext, response);
            return false;
        }

        close();

        eventBus.trigger("item-changed", segmentationJob);
        return true
    }

    return (
        <AppModal show={show} onHide={cancel} name={"field editor"}>
            <Modal.Header closeButton>
                <Modal.Title>New Segmentation Job</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <AppForm
                    onSubmit={submit}
                    onCancel={cancel}
                    initialValueLazy={initialFieldValue}
                    schema={SegmentationJobSchema}
                    fields={[
                        {
                            fieldType: "text",
                            tip: "job name",
                            field: SegmentationJobSchema.Name,
                        },
                        {
                            fieldType: "multi-select",
                            field: SegmentationJobSchema.SegmentClassIds,
                            options: segmentationClassOptions,
                        },
                        {
                            fieldType: "select",
                            tip: "Which group of leads to segment. Select none to segment all of your leads.",
                            field: SegmentationJobSchema.LeadGroupId,
                            options: groupsOptions,
                        },
                        {
                            fieldType: "toggle",
                            tip: "Include leads that don't match to any segment in results.",
                            field: SegmentationJobSchema.ExcludeNoneClass,
                        }
                    ]}
                />
            </Modal.Body>
        </AppModal>
    );
}

interface Props {
    show: boolean;
    close: () => void;
    edit_id: string | null;
}

export default SegmentationJobEditorModal;
