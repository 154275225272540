"auth/user-disabled"

function Disabled() {
    return (
        <div className="mx-auto container m-5 text-center">
            <h1>Account Disabled</h1>
            <p>Sorry, it looks like your account is disabled. Please reach out to support for help.</p>
        </div>
    );
}

export default Disabled;