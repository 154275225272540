import UsernamePasswordSignin from "./UsernamePasswordSignin";
import GoogleSignin from "./GoogleSignin";
import SimplePageContent from "../../components/./simplePage/SimplePageContent";

function SignIn() {
    return (
        <SimplePageContent title={"Sign In"} desc={"Access your Zensend account."}>
            <div className="container mx-auto shadow m-5 p-5 rounded bg-light offset-0 col-12 offset-sm-4 col-sm-4">
                <div className={"p-3"}>
                    <div className={"row"}>
                        <GoogleSignin/>
                    </div>
                </div>
                <div className="m-3 gap-2 col-4 mx-auto border-bottom"/>
                <div className={"text-center m-4"}>Or use email and password</div>
                <UsernamePasswordSignin/>
            </div>
        </SimplePageContent>
    )
}

export default SignIn