import Button from "react-bootstrap/Button"
import {getAuth, GoogleAuthProvider, signInWithPopup} from "firebase/auth"
import {DefaultSigninRedirect} from "../Routes"
import {useAppNav} from "../../hooks/useAppNav"

function GoogleSignin() {

    const navigate = useAppNav()

    const signInGoogle = async () => {
        const auth = getAuth()

        const provider = new GoogleAuthProvider()

        try {
            const result = await signInWithPopup(auth, provider)
            const user = result.user
            console.log(`Google sign in success! ${user.uid}`)
            navigate(DefaultSigninRedirect)
        } catch (error) {
            if (error instanceof Error) {
                const errorCode = "code" in error ? error.code : "unknown"
                const errorMessage = error.message
                console.log(`Failed log in: ${error}: ${errorMessage}`)
                if ("errorCode" in error) {
                    if (errorCode == "auth/user-disabled") {
                        navigate("/disabled")
                    }
                }
            } else {
                console.error(error)
            }
        }
    }

    return (
        <Button onClick={signInGoogle} size={"sm"}><img src={"/google-logo.png"} width={22} height={22}
                                                        className={"me-2"}
                                                        alt={"Google Logo"}/>Continue
            with
            Google</Button>
    )
}

export default GoogleSignin