import {ReactNode} from "react";

function SimplePageContent(props: Props) {

    return (
        <div className={"container"}>
            <h2 className={"ls-tight fw-bolder display-3 text-white mb-2 mt-5"}>{props.title}</h2>
            <p className="w-xl-75 lead text-white mb-5">{props.desc}</p>
            {props.children}
        </div>
    )
}

interface Props {
    readonly title: string
    readonly desc: string
    readonly children: ReactNode
}

export default SimplePageContent