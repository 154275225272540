import AppModal from "../../components/AppModal";
import AppForm, {FormSubmitPayload} from "../../components/forms/AppForm";
import {getResultOrNull, Organization, OrganizationSchema} from "lib-shared";
import {Modal} from "react-bootstrap";
import {OrganizationsClient} from "lib-client";
import {toastIfError} from "../../context/toasts/ToastManager";
import {UserContext} from "../../context/UserContext";
import {useContext} from "react";
import {AppClientContext, hardNavToOrg} from "../../utils/Utils";
import {JoinOrgPath} from "./SetupJoinOrgPage";

function CreateOrgModal(props: Props) {
    const {clientContext} = useContext(UserContext);

    async function create(payload: FormSubmitPayload<Organization>): Promise<boolean> {
        return createOrg(payload, clientContext)
    }

    return (
        <AppModal name={"create org"} show={props.show} onHide={props.onHide}>
            <Modal.Header closeButton={true}>Create Org</Modal.Header>
            <Modal.Body className={""}>
                <p>Create a new org or <a href={JoinOrgPath}>join an existing org</a>.</p>
                <AppForm
                    onSubmit={create}
                    schema={OrganizationSchema}
                    submitLabel={"Create"}
                    fields={[
                        {
                            fieldType: "text",
                            field: OrganizationSchema.Name
                        }
                    ]}
                />
            </Modal.Body>
        </AppModal>
    )
}

interface Props {
    show: boolean
    onHide: () => void
}

export async function createOrg(payload: FormSubmitPayload<Organization>, clientContext: AppClientContext): Promise<boolean> {
    let org: Organization | null = {
        ...payload.value,
        org_id: "",
        enable_third_party_data_enrichment: false,
    }

    const response = await new OrganizationsClient().create(org, clientContext)
    org = getResultOrNull(response)
    if (!org) {
        toastIfError(clientContext, response)
        return false
    }

    console.log(`Created new org ${org.org_id}`)

    hardNavToOrg(org)
    return true
}

export default CreateOrgModal