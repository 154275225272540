import * as React from "react";
import {useContext, useState} from "react";
import Modal from "react-bootstrap/Modal";
import {isSuccess, LeadGroup, LeadGroupSchema} from "lib-shared";
import {UserContext} from "../../context/UserContext";
import {LeadGroupsClient} from "lib-client";
import {eventBus} from "../../utils/EventBus";
import AppForm, {FormSubmitPayload} from "../forms/AppForm";
import {v4 as uuidv4} from "uuid"
import AppModal from "../AppModal";
import {toastIfError} from "../../context/toasts/ToastManager";

function AddGroupModal(props: Props) {
    const {clientContext} = useContext(UserContext);

    const {show, close} = props

    const cancel = () => {
        close()

        if (props.callbackCancel)
            props.callbackCancel()
    }

    async function submit(payload: FormSubmitPayload<LeadGroup>): Promise<boolean> {

        const group: LeadGroup = {
            ...payload.value,
            org_id: "",
            lead_group_id: uuidv4(),
        }

        const response = await new LeadGroupsClient().create( group, clientContext)

        if (!isSuccess(response)) {
            toastIfError(clientContext, response)
            return false
        }

        close()

        if (props.callback)
            props.callback(group)

        eventBus.trigger("item-changed", group)
        return true
    }

    return (
        <AppModal show={show} onHide={cancel} name={"new group"}>
            <Modal.Header closeButton>
                <Modal.Title>New Group</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <AppForm onSubmit={submit}
                         onCancel={cancel}
                         submitLabel={"Add Group"}
                         schema={LeadGroupSchema}
                         fields={[
                             {
                                 fieldType: "text",
                                 field: LeadGroupSchema.Name,
                             }
                         ]}
                />
            </Modal.Body>
        </AppModal>
    )
}

interface Props {
    show: boolean
    close: () => void
    callback?: (group: LeadGroup) => void
    callbackCancel?: () => void
}

export default AddGroupModal;