import {ReactNode} from "react";
import { Link } from "react-router-dom";
import {fixHref} from "../utils/Utils";

function SimplePage(props: Props) {
    return (
        <div className={"h-100 pt-5 pb-10 pb-lg-0 position-relative gradient-bottom-right start-indigo middle-purple end-yellow"}>
            <div className="w-lg-75 mx-2 mx-lg-auto position-relative z-2 px-lg-3 py-0 shadow-5 rounded-3 rounded-lg-pill bg-dark">
                <nav className="navbar navbar-expand-lg navbar-dark p-0" id="navbar">
                    <div className="container px-sm-0">
                        <Link className="navbar-brand d-inline-block w-lg-64" to={fixHref("/")}>
                            <img src="/logo-light.png" className="h-rem-10 p-1" alt="ZENSEND"/>
                        </Link>
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                                data-bs-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false"
                                aria-label="Toggle navigation"><span className="navbar-toggler-icon"></span></button>
                        <div className="collapse navbar-collapse" id="navbarCollapse">
                            <ul className="navbar-nav gap-2 mx-lg-auto">
                            </ul>
                            <div className="navbar-nav align-items-lg-center justify-content-end gap-2 ms-lg-4 w-lg-64">
                                <a className="nav-item nav-link rounded-pill d-none d-lg-block" href="/signin">Sign
                                    in</a>
                                <a href="/register"
                                   className="btn btn-sm btn-white bg-dark-hover border-0 rounded-pill w-100 w-lg-auto mb-4 mb-lg-0">
                                    Get started
                                </a>
                            </div>
                        </div>
                    </div>
                </nav>
            </div>
            {props.children}
        </div>
    )
}

interface Props {
    readonly children: ReactNode
}

export default SimplePage