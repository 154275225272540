import * as React from "react";
import {useContext} from "react";
import {Organization, OrganizationSchema} from "lib-shared";
import {UserContext} from "../../context/UserContext";
import SimplePageContent from "../../components/./simplePage/SimplePageContent";
import {JoinOrgPath} from "./SetupJoinOrgPage";
import AppForm, {FormSubmitPayload} from "../../components/forms/AppForm";
import {fixHref} from "../../utils/Utils";
import {Link} from "react-router-dom";
import {createOrg} from "./CreateOrgModal";

function SetupCreateOrgPage(props: Props) {
    const {clientContext} = useContext(UserContext);

    async function create(payload: FormSubmitPayload<Organization>): Promise<boolean> {
        return createOrg(payload, clientContext)
    }

    return (
        <SimplePageContent title={"Account Setup"} desc={"Let's finish setting up your Account"}>
            <div className="container mx-auto card p-5 offset-0 col-12 offset-sm-3 col-sm-6">
                <div className="alert alert-info mb-3" role="alert">Do you have an Invite Code or has someone else from
                    your company already set things up? <Link to={fixHref(JoinOrgPath)}>Click here</Link>
                </div>
                <AppForm
                    onSubmit={create}
                    schema={OrganizationSchema}
                    submitLabel={"Create"}
                    fields={[
                        {
                            fieldType: "text",
                            field: OrganizationSchema.Name
                        }
                    ]}
                />
            </div>
        </SimplePageContent>
    )
}

export const SetupOrgPath = "/setup-org"

interface Props {

}

export default SetupCreateOrgPage