import PowerPageContent from "../../components/powerPage/PowerPageContent";
import {SocialMediaProfile, SocialMediaProfileSchema} from "lib-shared";
import * as React from "react";
import {ColumnSpec, FilterOption} from "../../components/tables/BaseTable";
import ApiTable, {ApiFilter, ApiOrderBy, createColumn} from "../../components/tables/ApiTable";
import {TableAction} from "../../components/tables/TableActions";
import {useAppNav} from "../../hooks/useAppNav";
import {fixHref} from "../../utils/Utils";
import {SocialMediaProfilesClient} from "lib-client";
import {platformWithIcon} from "../../utils/ReactUtils";
import {makeProfileLink} from "./SocialMediaProfilePage";
import NewSocialMediaProfileModal from "./NewSocialMediaProfileModal";
import {useState} from "react";
import IconButton from "../../components/common/IconButton";
import ProfileAvatarIcon from "../../components/common/ProfileAvatarIcon";

function SocialMediaProfilesPage() {
    const nav = useAppNav()

    const [showNewProfileModal, setShowNewProfileModal] = useState(false)

    const columns: ColumnSpec<SocialMediaProfile, ApiOrderBy>[] = [
        createColumn(SocialMediaProfileSchema.Platform, true, {
            valueFormatter: value => {
                return platformWithIcon(value)
            }
        }),
        createColumn(SocialMediaProfileSchema.Username, true, {
            cellConfig: {weight: "fw-semibold"}, valueFormatter: (value, model) => {
                return <span><ProfileAvatarIcon src={model.avatar_uri} style={"rounded-circle"} size={24}
                                                className={"me-1"}/>{model.username}</span>
            }
        }),
        createColumn(SocialMediaProfileSchema.RawInfo, false),
        createColumn(SocialMediaProfileSchema.EnrichedInfo, false),
        createColumn(SocialMediaProfileSchema.FollowingCount, false),
        createColumn(SocialMediaProfileSchema.FollowerCount, true),
        createColumn(SocialMediaProfileSchema.HeartCount, true),
        createColumn(SocialMediaProfileSchema.VideoCount, true),
        createColumn(SocialMediaProfileSchema.DiggCount, false),
        createColumn(SocialMediaProfileSchema.Nickname, false),
        createColumn(SocialMediaProfileSchema.ProfileCreateTime, false),
        createColumn(SocialMediaProfileSchema.Signature, false),
        createColumn(SocialMediaProfileSchema.BioLink, false),
        createColumn(SocialMediaProfileSchema.ShareMetaTitle, false),
        createColumn(SocialMediaProfileSchema.ShareMetaDescription, false),
        createColumn(SocialMediaProfileSchema.TiktokId, false),
        createColumn(SocialMediaProfileSchema.UpdatedAt, false),
        createColumn(SocialMediaProfileSchema.CreatedAt, false),
    ]

    const filters: FilterOption<ApiFilter>[] = [
        {
            name: "Platform",
            filters: [
                {
                    displayName: "Platform",
                    field: "platform",
                    value: ["instagram"],
                    displayValue: "Instagram"
                },
                {
                    displayName: "Platform",
                    field: "platform",
                    value: ["TIKTOK"],
                    displayValue: "TIKTOK"
                },
            ]
        },
    ]

    function actionCreator(profiles: SocialMediaProfile[]): TableAction[] {
        return []
    }

    function rowClicked(profile: SocialMediaProfile) {
        nav(fixHref(makeProfileLink(profile)))
    }

    return (
        <PowerPageContent title={"Social Media Profiles"}
                          buttons={[
                              <IconButton key={"new-profile"} action={() => setShowNewProfileModal(true)}
                                          icon={"plus-circle"}
                                          label={"New Profile"}/>,
                          ]}>
            <ApiTable<SocialMediaProfile> client={SocialMediaProfilesClient.defaultClient}
                                          noDataMessage={"No Profiles."}
                                          tableSettings={{
                                              idExtractor: value => `${value.platform}#${value.username}`,
                                              filterOptions: filters,
                                              rowClickCallback: rowClicked,
                                              columns: columns,
                                              actionCreator
                                          }}
            />
            <NewSocialMediaProfileModal show={showNewProfileModal} close={() => setShowNewProfileModal(false)}/>
        </PowerPageContent>
    )
}

export default SocialMediaProfilesPage