import {UserContext} from "../../context/UserContext";
import LoadingFullPage from "../common/LoadingFullPage";
import AppNavigate from "../../hooks/AppNavigate";
import {SetupUserPath} from "../../routes/setup/SetupUser";
import {ReactNode, useContext} from "react";

function IsUserSetUpGuard(props: Props) {

    const {loadingAppUser, appUser} = useContext(UserContext);

    if (loadingAppUser)
        return <LoadingFullPage/>

    if (appUser == null) {
        return <AppNavigate to={SetupUserPath}/>
    }

    return <>{props.children}</>
}

interface Props {
    readonly children: ReactNode
}

export default IsUserSetUpGuard