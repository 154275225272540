import {InvitationSchema, KeyValue, Organization, Organization_Id, OrgMembership} from "lib-shared";
import {ToastManagerProps} from "../context/toasts/ToastManager";
import {FormEvent, ReactNode} from "react";
import {ApiResult} from "../hooks/useApi";
import {ClientContext} from "lib-client";

export function dateToNiceTimeAgo(date: Date): string {
    const nowSeconds = (new Date().getTime() - date.getTime()) / 1000
    return secondsToNiceTimeAgo(nowSeconds)
}

export function hardNavToOrg(org: Organization | OrgMembership): void {
    // reloading the page works best when switching org so we don't use react router
    // this is because of how OrgContext work - it's not perfect but it's more than good enough
    window.location.assign(`/?${Organization_Id}=${org.org_id}`)
}

export function paginateArray<T>(data: T[], pageNum: number, pageSize: number, filter: (value: T) => boolean) {
    const page = data
        .filter(filter)
        .slice((pageNum * pageSize), pageSize * (pageNum + 1))

    return page
}

// pageNum is 0 based
export function getPageNums(pageNum: number, pageSize: number, total: number): PageNums {

    if (total == 0) {
        return {
            startNum: 0,
            endNum: 0,
            totalCount: 0,
            hasPrevPage: false,
            hasNextPage: false,
        }
    }

    const startNum = pageNum * pageSize + 1
    const endNum = Math.min(total ?? 0, (pageNum + 1) * pageSize)
    const totalCount = total ?? 0

    const hasPrevPage = pageNum > 0
    const hasNextPage = endNum < totalCount

    return {
        startNum,
        endNum,
        totalCount,
        hasPrevPage,
        hasNextPage,
    }
}

export interface PageNums {
    startNum: number
    endNum: number
    totalCount: number
    hasPrevPage: boolean
    hasNextPage: boolean
}


export function blockForm(func: () => Promise<void>): (e: FormEvent<HTMLFormElement>) => void {
    return (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        func()
    }
}

export function lerpColors(percent01: number, colors: string[]) {
    // clamp 01
    percent01 = Math.min(percent01, 1)
    percent01 = Math.max(percent01, 0)

    if (colors.length == 1)
        return colors[0]
    if (colors.length == 0)
        return "#ffffff"

    const sectionPercent = 1 / (colors.length - 1)
    // [0,1,2,3] sp = .25
    // .2 = 0,1
    // .4 = 1,2
    // .9 = 2,3
    const color1Index = Math.min(Math.floor(percent01 / sectionPercent), colors.length - 2)
    const color2Index = Math.min(Math.floor(percent01 / sectionPercent) + 1, colors.length - 1)
    const sectionProgress01 = (percent01 - color1Index * sectionPercent) / (sectionPercent)
    const color1 = colors[color1Index]
    const color2 = colors[color2Index]


    // Convert the hex colors to RGB values
    const r1 = parseInt(color1.substring(1, 3), 16);
    const g1 = parseInt(color1.substring(3, 5), 16);
    const b1 = parseInt(color1.substring(5, 7), 16);

    const r2 = parseInt(color2.substring(1, 3), 16);
    const g2 = parseInt(color2.substring(3, 5), 16);
    const b2 = parseInt(color2.substring(5, 7), 16);

    // Interpolate the RGB values
    const r = Math.round(r1 + (r2 - r1) * sectionProgress01);
    const g = Math.round(g1 + (g2 - g1) * sectionProgress01);
    const b = Math.round(b1 + (b2 - b1) * sectionProgress01);

    // Convert the interpolated RGB values back to a hex color
    return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
}


export type ColorType = "success" | "danger" | "info" | "warning" | "primary" | "muted"

export function colorTypeToClass(color?: ColorType): string {
    switch (color) {
        case "success":
            return "text-success"
        case "danger":
            return "text-danger"
        case "info":
            return "text-info"
        case "warning":
            return "text-warning"
        case "primary":
            return "text-primary"
        case "muted":
            return "text-muted"
        default:
            return ""
    }
}

export function toFixedClean(num: number): string {
    const result = num.toFixed(1)
    if (result.endsWith(".0"))
        return result.substring(0, result.length - 2)
    return result
}


export function numberToShortNum(num: number): string {
    if (num < 1000) {
        return num + ""
    } else if (num < 1000000) {
        return toFixedClean(num / 1000) + "K"
    } else {
        return toFixedClean(num / 1000000) + "M"
    }
}

export function secondsToNiceTimeAgo(seconds: number) {
    if (seconds < 60) {
        return `${Math.round(seconds)} seconds ago`
    } else if (seconds < 60 * 60) {
        return `${Math.round(seconds / 60)} minutes ago`
    } else if (seconds < 60 * 60 * 24) {
        return `${Math.round(seconds / 60 / 60)} hours ago`
    } else if (seconds < 60 * 60 * 24 * 400) {
        return `${Math.round(seconds / 60 / 60 / 24)} days ago`
    } else {
        return `${Math.round(seconds / 60 / 60 / 24 / 365)} years ago`
    }
}

export function getQueryParam(key: string): string | null {
    return new URLSearchParams(window.location.search).get(key)
}

const adminViewKey = "admin_view"

export function isAdminMode(): boolean {
    const currentParams = new URLSearchParams(window.location.search)
    return currentParams.get(adminViewKey) == "true"
}

export function fixHref(href: string, opts?: AppNavOptions): string {
    const {pathname, search} = fixLink(href, null, opts)
    return `${pathname}${search}`
}

export function fixLink(path: string, org?: Organization | null, opts?: AppNavOptions): {
    pathname: string,
    search: string
} {
    const url = new URL(window.location.href)
    const currentParams = new URLSearchParams(window.location.search)


    const split = path.split("?")
    if (split.length == 1) {
        url.pathname = path
    } else {
        url.pathname = split[0]
        url.search = split[1]
    }
    opts?.params?.forEach((value: string, key: string) => {
        url.searchParams.set(value, key)
    })

    // org_id is handled separately, right after this
    const defaultKeepParams: string[] = [InvitationSchema.InvitationId.name]
    const keeps: string[] = [...defaultKeepParams, ...(opts?.keepParams ?? [])]
    keeps.forEach((param: string) => {
        const value = currentParams.get(param)
        if (value)
            url.searchParams.set(param, value)
    })

    const orgId = opts?.orgOverride ?? org?.org_id ?? currentParams.get(Organization_Id)
    if (orgId) {
        url.searchParams.set(Organization_Id, orgId)
    }

    opts?.dropParams?.forEach((dropParam: string) => {
        url.searchParams.delete(dropParam)
    })

    return {
        pathname: url.pathname,
        search: url.search
    }
}

export interface AppNavOptions {
    orgOverride?: string
    keepParams?: string[]
    dropParams?: string[]
    params?: URLSearchParams
}


export function delay(ms: number): Promise<void> {
    return new Promise(
        resolve => setTimeout(resolve, ms)
    );
}

// yeah, I know this can be done in css but honestly this is faster to set up and I don't have to explain myself to you
export function ellipsis(str: string, max: number): string {
    if (!str) {
        return ""
    }
    if (str.length > max) {
        return str.substring(0, max) + "..."
    }
    return str
}


export interface AppClientContext extends ClientContext {
    toastProps: ToastManagerProps
}


export function compactResult<T>(apiResult: ApiResult<T[]>, extractor: (t: T) => [string, string]): ApiResult<KeyValue<string>> {
    const result: KeyValue<string> | null = compact(apiResult.value ?? [], extractor) ?? null
    return {
        ...apiResult,
        value: result
    }
}

export function compact<T>(array: T[], extractor: (t: T) => [string, string]): { [key: string]: string } {
    return array.reduce<{ [key: string]: string }>((p, curr) => {
        const [k, v] = extractor(curr)
        return {
            ...p,
            [k]: v
        }
    }, {})
}