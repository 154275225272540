import {UserContext} from "../../context/UserContext";
import {eventBus} from "../../utils/EventBus";
import AppModal from "../../components/AppModal";
import AppForm, {FormSubmitPayload} from "../../components/forms/AppForm";
import {useContext} from "react";
import {FirstPartyField, FirstPartyFieldSchema, isSuccess} from "lib-shared";
import {FirstPartyFieldsClient} from "lib-client";
import {Modal} from "react-bootstrap";
import {useApiGet} from "../../hooks/useApiGet";
import {toastIfError} from "../../context/toasts/ToastManager";

function FirstPartyFieldEditorModal(props: Props) {
    const {fbUser, clientContext, appUser} = useContext(UserContext);

    const isCreate = props.edit_id == ""
    const {show, close} = props

    const initialFieldValue = useApiGet<FirstPartyField>(clientContext, FirstPartyFieldsClient.defaultClient, props.edit_id)

    const cancel = () => {
        close()
    }

    async function submit(payload: FormSubmitPayload<FirstPartyField>): Promise<boolean> {

        const firstPartyField: FirstPartyField = {
            ...payload.value,
            org_id: "",
        }

        const response = await FirstPartyFieldsClient.defaultClient
            .createOrUpdate(firstPartyField, isCreate, clientContext)

        if (!isSuccess(response)) {
            toastIfError(clientContext, response)
            return false
        }

        if (props.createFieldCallback)
            props.createFieldCallback(firstPartyField)

        close()

        eventBus.trigger("item-changed", firstPartyField)
        return true
    }

    return (
        <AppModal show={show} onHide={cancel} name={"field editor"}>
            <Modal.Header closeButton>
                <Modal.Title>{isCreate ? "New" : "Edit"} Field</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <AppForm onSubmit={submit}
                         onCancel={cancel}
                         initialValueLazy={initialFieldValue}
                         schema={FirstPartyFieldSchema}
                         fields={[
                             {
                                 fieldType: "text",
                                 field: FirstPartyFieldSchema.FieldId
                             },
                             {
                                 fieldType: "toggle",
                                 tip: "Display only fields are not shown to your AI.",
                                 field: FirstPartyFieldSchema.DisplayOnly
                             },
                             {
                                 fieldType: "text",
                                 tip: "This description helps your AI understand what this field means.",
                                 field: FirstPartyFieldSchema.FieldDescription
                             }
                         ]}

                />
            </Modal.Body>
        </AppModal>
    );
}

interface Props {
    show: boolean
    createFieldCallback?: (field: FirstPartyField) => void
    close: () => void
    edit_id: string | null
}

export default FirstPartyFieldEditorModal