import Button from "react-bootstrap/Button";
import MaterialIcon from "./MaterialIcon";
import {ButtonVariant} from "react-bootstrap/types";

function IconButton(props: Props) {
    return (
        <Button className={`d-none d-sm-inline-flex ${props.className ?? ""}`} size={"sm"} variant={props.variant ?? "primary"} onClick={props.action} disabled={props.disabled}>
            <MaterialIcon
            icon={props.icon}
            margin={2}
            size={16}/>{props.label}
        </Button>
    )
}

interface Props {
    readonly variant?: ButtonVariant;
    readonly className?: string
    readonly disabled?: boolean
    readonly action: () => void
    readonly icon: string
    readonly label: string
}

export default IconButton