import {
    ApiResponse,
    IOrganizationsService,
    ModelSchema,
    Organization,
    OrganizationSchema,
    SetUpUserRequest
} from "lib-shared";
import {BaseCrudClient} from "./BaseCrudClient";
import {ClientContext, sendApi} from "../FetchUtils";

export class OrganizationsClient extends BaseCrudClient<Organization> implements IOrganizationsService<ClientContext> {
    public static readonly defaultClient: OrganizationsClient = new OrganizationsClient()
    get schema(): ModelSchema<Organization> {
        return OrganizationSchema;
    }

    adminJoin(req: SetUpUserRequest, ctx: ClientContext): Promise<ApiResponse<void>> {
        return sendApi(`/admin/${this.resourceName}/join`, "POST", req, ctx);
    }
}
