import * as React from "react";
import {getDuplicates, LeadSchema, validate} from "lib-shared";
import {ColumnSpec, FilterOption} from "../../../components/tables/BaseTable";
import MaterialIcon from "../../../components/common/MaterialIcon";
import InMemTable, {InMemOrderBy, InMemoryFilterField} from "../../../components/tables/InMemTable";
import {TableAction} from "../../../components/tables/TableActions";
import {StagedBulkUploadLead} from "./BulkAddLeadsPage";

function BulkAddLeadsTable(props: Props) {
    const {leads} = props

    const columns: ColumnSpec<StagedBulkUploadLead, InMemOrderBy<StagedBulkUploadLead>>[] = [
        {
            name: "First Name",
            defaultColumn: true,
            orderBy: {
                label: "First Name",
                extractor: lead => lead.lead.first_name ?? "",
            },
            cellExtractor: value => {
                return {
                    value: value.lead.first_name
                }
            },
        },
        {
            name: "Last Name",
            defaultColumn: true,
            orderBy: {
                label: "Last Name",
                extractor: lead => lead.lead.last_name ?? "",
            },
            cellExtractor: value => {
                return {
                    value: value.lead.last_name
                }
            },
        },
        {
            name: "Email",
            defaultColumn: true,
            orderBy: {
                label: "Email",
                extractor: lead => lead.lead.email,
            },
            cellExtractor: value => {
                return {
                    value: value.lead.email
                }
            },
        },
        {
            name: "Errors",
            defaultColumn: true,
            cellExtractor: value => {
                const validationResult = validate(value.lead, LeadSchema)
                return {
                    value: validationResult.isValid ? "" :
                        <span><MaterialIcon icon={"exclamation-circle"} size={18} margin={2}/>Please Fix</span>,
                    color: validationResult.isValid ? undefined : "text-danger"
                }
            },
        },
    ]

    const duplicates = getDuplicates(leads, (v) => v.lead.lead_id)


    const filterOptions: FilterOption<InMemoryFilterField<StagedBulkUploadLead>>[] = [
        {
            name: "Has Errors",
            filters: [
                {
                    displayName: "Has Errors",
                    predicate: (value: StagedBulkUploadLead) => !validate(value.lead, LeadSchema).isValid,
                    displayValue: "True"
                },
                {
                    displayName: "Has Errors",
                    predicate: (value: StagedBulkUploadLead) => validate(value.lead, LeadSchema).isValid,
                    displayValue: "False"
                }
            ]
        },
        {
            name: "Duplicates",
            filters: [
                {
                    displayName: "Duplicates",
                    predicate: (value: StagedBulkUploadLead) => duplicates.includes(value),
                    displayValue: "True"
                },
                {
                    displayName: "Duplicates",
                    predicate: (value: StagedBulkUploadLead) => !duplicates.includes(value),
                    displayValue: "False"
                }
            ]
        }
    ]

    function actionCreator(values: StagedBulkUploadLead[]): TableAction[] {
        return [
            {
                icon: "pencil",
                label: "Edit",
                supportsMultiRows: false,
                action: () => props.onLeadClicked(values[0])
            },
            {
                icon: "trash",
                label: "Delete",
                color: "danger",
                supportsMultiRows: true,
                action: () => props.deleteLeads(values)
            }
        ]
    }

    return (
        <>
            <InMemTable
                tableSettings={{
                    idExtractor: x => x.id,
                    columns: columns,
                    actionCreator: actionCreator,
                    filterOptions: filterOptions,
                    rowClickCallback: (lead: StagedBulkUploadLead) => {
                        props.onLeadClicked(lead)
                    },
                }}
                noDataMessage={"No Leads."}
                data={leads}/>
        </>

    )
}

interface Props {
    leads: StagedBulkUploadLead[]
    onLeadClicked: (lead: StagedBulkUploadLead) => void
    deleteLeads: (lead: StagedBulkUploadLead[]) => void
}

export default BulkAddLeadsTable